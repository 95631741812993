import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

function Landing() {
  
  const contentArray = [
    {
      heading: 'Empowering Future Tech Innovators',
      description: 'Master programming skills and career readiness with expert-led courses, mock interviews, and mentorship.',
      buttonText: 'Get Started',
    },
    {
      heading: 'Transform Your Skills in Technology',
      description: 'Join us to gain industry-relevant skills and make an impact in the tech world.',
      buttonText: 'Join Now',
    },
    {
      heading: 'Elevate Your Career in Tech',
      description: 'Get hands-on experience with real-world projects and accelerate your career growth.',
      buttonText: 'Learn More',
    },
  ];

 
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentArray.length);
    }, 5000);

  
    return () => clearInterval(interval);
  }, [contentArray.length]);


  const { heading, description, buttonText } = contentArray[currentIndex];

  return (
    <section className="relative z-10 flex flex-col items-center justify-center min-h-screen text-center p-6">
      <motion.h1
        key={heading} 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.8 }}
        className="text-5xl md:text-7xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500"
      >
        {heading}
      </motion.h1>

      <motion.p
        key={description} 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="text-xl md:text-2xl mb-8 max-w-2xl"
      >
        {description}
      </motion.p>

      <motion.div
        key={buttonText}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-full text-lg">
          {buttonText}
        </button>
      </motion.div>
    </section>
  );
}

export default Landing;

import React, { useEffect, useState } from 'react';
import Background from "../../components/ui/Background";
import Button2 from '../Button2/MagicBtn';
import { useNavigate } from 'react-router-dom';
import { logout } from "../../firebase";
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function LiveClass({ name }) {
  const [classDetails, setClassDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    studentName: '',
    courseName: '',
    rating: '',
    feedback: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
  };


  useEffect(() => {
    fetch('/JSON_FOLDER/class.json')
      .then((response) => response.json())
      .then((data) => setClassDetails(data))
      .catch((error) => console.error("Error loading class details:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRatingChange = (value) => {
    setFormData(prevData => ({
      ...prevData,
      rating: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxp_ZL9ALycx_BgzInDWEqtc51nwv2PB87UEOH07FeHMCAYavWd3pjoEkJoDYskhiud/exec', {
        method: 'POST',
        body: new URLSearchParams(formData),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        alert('Failed to submit feedback: ' + errorText);
      } else {
        alert('Feedback submitted successfully!');
        setModalIsOpen(false);
        setFormData({ name: '', email: '', feedback: '' });
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting feedback: ' + error.message);
    }
  };


  if (!classDetails) {
    return <div className="text-center mt-30">Loading class details...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white overflow-hidden">
      <Background />
      <section className="flex justify-between p-5 items-center mt-3">
        <button className="dashboard__btn font-semibold backdrop-blur-md bg-black p-4 rounded-full" onClick={goToHome}>
          Back To Home
        </button>
        <button className="dashboard__btn font-semibold backdrop-blur-md bg-black p-4 rounded-full " onClick={logout}>
          Logout
        </button>
      </section>
      <section className="relative z-10  p-6">
        <div className="backdrop-blur-md">
          <h3 className="relative text-center mt-15 text-4xl">
            <span className="relative z-10 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
              Welcome To PNexus <span className="block sm:inline">{name}</span>
            </span>
          </h3>
        </div>

        <div className="backdrop-blur-md">
          <h2 className="text-3xl mt-5 text-center font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">Today Class Details</h2>
        </div>
      </section>


      <section className="relative z-10 py-15 px-6">
        <div className="p-6 rounded-lg bg-white/10 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-2/5 shadow-lg backdrop-blur-md mx-auto">
          <h3 className="text-3xl font-bold underline mb-10 text-center">Course {classDetails.courseName}</h3>
          <div className="flex items-center space-x-2">
            <h6 className="text-xl font-semibold whitespace-nowrap">Today Topics:</h6>
            <p>{classDetails.topics.join(', ')}</p>
          </div>

          <div className="flex items-center space-x-4 mt-8">
            <h6 className="text-xl font-semibold">Time:</h6>
            <p>{classDetails.time}</p>
          </div>

          <div className="flex items-center space-x-4 mt-8">
            <h6 className="text-xl font-semibold">Class Link:</h6>
            <Button2 className="" name={"Click Here"} onClick={() => window.open(classDetails.classLink, "_blank")} />
          </div>

          <div className="flex items-center space-x-4 mt-8">
            <h6 className="text-xl font-semibold">Feedback:</h6>
            {/* <button className="" name={"Click Here"}  /> */}
            <Button2 className="" name={"Click Here"} onClick={() => setModalIsOpen(true)} />
            {/* <button onClick={() => setModalIsOpen(true)}>Click Here</button> */}
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000,
          },
          content: {
            zIndex: 1001,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
            borderRadius: '10px',
            backgroundColor: '#1f2937',
            color: 'white',
          }
        }}
      >
        <h2 className="text-2xl font-bold mb-4">Feedback Form</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="studentName" className="block text-sm font-medium text-white">Student Name</label>
            <input
              id="studentName"
              name="studentName"
              type="text"
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={formData.studentName}
              onChange={handleChange}
              placeholder="Your Name"
            />
          </div>
          <div>
            <label htmlFor="courseName" className="block text-sm font-medium text-white">Course Name</label>
            <input
              id="courseName"
              name="courseName"
              type="text"
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={formData.courseName}
              onChange={handleChange}
              placeholder="Course Name"
            />
          </div>
          <div>
            <label htmlFor="rating" className="block text-sm font-medium text-white">Rating</label>
            <select
              id="rating"
              name="rating"
              required
              className="mt-1 block w-full px-9  py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={formData.rating}
              onChange={handleChange}
            >
              <option value="">Select a rating</option>
              <option value="5">5 - Excellent</option>
              <option value="4">4 - Very Good</option>
              <option value="3">3 - Good</option>
              <option value="2">2 - Fair</option>
              <option value="1">1 - Poor</option>
            </select>
          </div>
          <div>
            <label htmlFor="feedback" className="block text-sm font-medium text-white">Feedback</label>
            <textarea
              id="feedback"
              name="feedback"
              rows={4}
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={formData.feedback}
              onChange={handleChange}
              placeholder="Please provide your feedback here"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
          </button>
          {submitMessage && (
            <div className={`mt-2 text-center ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {submitMessage}
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
}
